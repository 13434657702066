import {CashRegisterActions} from "../types/CashRegisterTypes";
import * as actionTypes from '../actions/actionTypes';

export const showCashRegisterAuthView = (): CashRegisterActions => {
    return {
        type: actionTypes.AUTH_SHOW_VIEW
    }
}
export const hideCashRegisterAuthView = (): CashRegisterActions => {
    return {
        type: actionTypes.AUTH_HIDE_VIEW,
    }
}

export const activateCashRegister = (flag: boolean): CashRegisterActions => {
    localStorage.setItem('cashRegisterActive', flag ? 'true' : 'false' );

    return {
        type: actionTypes.AUTH_ACTIVATE,
        active: flag
    }
}


export const authData = (username: string, password: string): CashRegisterActions => {
    localStorage.setItem('cashRegisterUsername', username);
    localStorage.setItem('cashRegisterPassword', password);


    return {
        type: actionTypes.AUTH_SAVE,
        username,
        password,
    }
};
