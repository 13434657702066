import React from 'react';
import { IOrder, IOrderItem } from '../../../store/types/Orders';
import './OrderItem.scss';
import { useDispatch, useSelector } from 'react-redux';

interface IProps {
    orderItem: IOrderItem;
    order: IOrder;
}

const OrderItem: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();

    let text: string | any = props.orderItem.text;

    if (text) {
        text = text.split('\n').map((item: string, key: number) => {
            return (
                <span key={key} className="orderText">
                    {item}
                    <br />
                    {props.orderItem.quantity ? (
                        <span
                            style={{
                                marginTop: 5,
                                fontSize: 14,
                                fontWeight: 400,
                            }}
                        >
                            Menge: {props.orderItem.quantity}
                        </span>
                    ) : null}
                </span>
            );
        });
    }

    if (text) {
        return <div style={{ padding: 10 }}>{text}</div>;
    } else {
        return <div />;
    }
};

export default OrderItem;
