import * as actionTypes from './actionTypes';
import { ChatActions } from '../types/chatActionTypes';
import { Dispatch } from 'redux';
import * as callSocketApi from '../../shared/socketApi';
import {IChatBucket, IChatElements} from '../types/Chat';
import {store} from '../configureStore';
import {getMessageFromData, markChatMessage, sendChatMessage} from "../../shared/chatHelper";
import {getPrivateKey} from "../../shared/csafeSocketApi";
import {addPrivateStartEndLine, privateDecryptFromBase64} from "../../shared/securityHelper";



export const getChatListStart = (): ChatActions => ({
    type: actionTypes.GET_CHAT_LIST_START
});

export const getChatListFail = (): ChatActions => ({
    type: actionTypes.GET_CHAT_LIST_FAIL
});

export const getChatListSuccess = (buckets: IChatBucket[]): ChatActions => ({
    type: actionTypes.GET_CHAT_LIST_SUCCESS,
    buckets: buckets
});

export const getChatList = () => {
    return async (dispatch: Dispatch<ChatActions>) => {
        dispatch(getChatListStart());
        let result = await callSocketApi.chatGetList();

        if (result && result.errorCode === 0 && result.buckets && Object.keys(result.buckets).length > 0) {
            let filteredBuckets :any = {};
            for (let bucket in result.buckets) {
                if (result.buckets.hasOwnProperty(bucket)) {

                    try {
                        let pharmacyId: any = store.getState().auth.pharmacyId;
                        let encryptedAesKeys_Base64 = result.buckets[bucket].encryption.v1.keys[pharmacyId].key;

                        let privateKey: any = await getPrivateKey();
                        let decryptAesKey = privateDecryptFromBase64(encryptedAesKeys_Base64, addPrivateStartEndLine(privateKey));
                        if (decryptAesKey) {
                            filteredBuckets[bucket] = result.buckets[bucket];
                        }

                    } catch (e) {
                        console.log('skip chat bucket');
                        console.log(e);
                    }
                }
            }
            dispatch(getChatListSuccess(filteredBuckets));
        } else {
            dispatch(getChatListFail());
        }
    }
};

export const initChatMessages = (bucketId: string) => {
    return async (dispatch: Dispatch<ChatActions>) => {
        let selectedChatBucket = store.getState().chats.chatBucketList[bucketId];
        dispatch(resetChatBadgeCount(bucketId));
        if (!selectedChatBucket.isMessagesLoaded) {
            try {
                let pharmacyId: any = store.getState().auth.pharmacyId;
                let encryptedAesKeys_Base64 = store.getState().chats.chatBucketList[bucketId].encryption.v1.keys[pharmacyId].key;

                let privateKey: any = await getPrivateKey();
                let decryptAesKey = privateDecryptFromBase64(encryptedAesKeys_Base64, addPrivateStartEndLine(privateKey));
                if (decryptAesKey) {
                    dispatch(getChatMessages(bucketId, store.getState().chats.chatBucketList[bucketId].encryption.v1.keys) as any);
                }
            } catch (e) {
                console.log('skip chat bucket');
                console.log(e);
            }
        } else {
            if (selectedChatBucket.messages) {
                let messages: IChatElements[] = selectedChatBucket.messages;
                if (selectedChatBucket.unreadElements > 0) {
                    for ( let key in messages) {
                        if (messages.hasOwnProperty(key)) {
                            // we only send mark as read for the messages we receive from client
                            if (messages[key].createdBy !== selectedChatBucket.displayPharmacyName) {
                                if (!messages[key].allReceived) {
                                    await markChatMessage(bucketId, messages[key].elementId, 0 );
                                }
                                if (!messages[key].allRead) {
                                    await markChatMessage(bucketId, messages[key].elementId, 1 );
                                }
                            }
                        }
                    }
                }
            }
        }
    }
};

export const getChatMessages = (bucketId: string, encryptionKeys: any) => {
    return async (dispatch: Dispatch<ChatActions>) => {
        dispatch(messagesLoadingStart());
        let result = await callSocketApi.chatGetMessages(bucketId);

        if (result.errorCode === 0) {
            let pharmacyId: any = store.getState().auth.pharmacyId;
            let selectedChatBucket = store.getState().chats.chatBucketList[bucketId];
            let messages: IChatElements[] = [];
            for (let key in result.elements) {
                if (result.elements.hasOwnProperty(key)) {
                    let encryptedAesKeys_Base64 = encryptionKeys[pharmacyId].key;
                    let messageData = await getMessageFromData(
                        result.elements[key],
                        encryptedAesKeys_Base64,
                        bucketId
                    );
                    messages.push(messageData);
                }
            }

            dispatch(setChatMessages( bucketId, messages, messages[0].elementId ));
            dispatch(messagesLoadingEnd());
            if (store.getState().chats.selectedChatBucketId === bucketId) {
                for (let key in messages) {
                    if (messages.hasOwnProperty(key)) {
                        // we only send mark as read for the messages we receive from client
                        if (messages[key].createdBy !== selectedChatBucket.displayPharmacyName) {
                            if (!messages[key].allReceived) {
                                await markChatMessage(bucketId, messages[key].elementId, 0 );
                            }
                            if (!messages[key].allRead) {
                                await markChatMessage(bucketId, messages[key].elementId, 1 );
                            }
                        }
                    }
                }
            }
        }
    }
};

export const setChatMessages = (bucketId: string, messages: IChatElements[], latestElementId: number): ChatActions => ({
    type: actionTypes.SET_CHAT_MESSAGES,
    bucketId: bucketId,
    messages: messages,
    latestElementId: latestElementId
});

export const toggleShowChatView = (): ChatActions => ({
    type: actionTypes.TOGGLE_SHOW_CHAT_VIEW
});

export const hideChatView = (): ChatActions => ({
    type: actionTypes.HIDE_CHAT_VIEW
});

export const resetChatData = (): ChatActions => ({
    type: actionTypes.RESET_CHAT_DATA
});

export const chatSelected = (bucketId: string | null): ChatActions => ({
    type: actionTypes.CHAT_SELECTED,
    bucketId: bucketId
});

export const messagesLoadingStart = (): ChatActions => ({
    type: actionTypes.MESSAGES_LOADING_START
});

export const messagesLoadingEnd = (): ChatActions => ({
    type: actionTypes.MESSAGES_LOADING_END
});

export const setTypedChatText = (bucketId: string, text: string): ChatActions => ({
    type: actionTypes.SET_TYPED_CHAT_TEXT,
    bucketId: bucketId,
    text: text
});

export const sendNewChatMessage = (bucketId: string, text: string) => {
    return async () => {
        try {
            await sendChatMessage(bucketId, text);
        } catch (e) {
            console.log('send chat message failed');
        }
    }
};

export const updateChatMessageReadReceipts = (bucketId: string, elementId: number, readReceipts: string): ChatActions => ({
    type: actionTypes.UPDATE_CHAT_MESSAGE_READ_RECEIPTS,
    bucketId: bucketId,
    elementId: elementId,
    readReceipts: readReceipts
});

export const addIncomingChatMessage = (bucketId: string, message: IChatElements): ChatActions => ({
    type: actionTypes.ADD_INCOMING_CHAT_MESSAGE,
    bucketId: bucketId,
    message: message
});

export const addChatBadgeCount = (bucketId: string): ChatActions => ({
    type: actionTypes.ADD_CHAT_BADGE_COUNT,
    bucketId: bucketId
});

export const resetChatBadgeCount = (bucketId: string): ChatActions => ({
    type: actionTypes.RESET_CHAT_BADGE_COUNT,
    bucketId: bucketId
});

export const addNewChatBucket = (bucket: IChatBucket): ChatActions => ({
    type: actionTypes.ADD_NEW_CHAT_BUCKET,
    bucket: bucket
});

export const setChatClose = (bucketId: string): ChatActions => ({
    type: actionTypes.SET_CHAT_CLOSE,
    bucketId: bucketId
});
