import React, {useEffect, useRef} from 'react';
import './ChatMessagesView.scss';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import {IChatElements} from "../../../store/types/Chat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatDateForOrderList} from "../../../shared/utility";
import {setChatClose} from "../../../store/actions/chat";

interface IProps {
}

const ChatMessagesView: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    // chat close
    const onChatCloseClick = (bucketId: string) => {
        dispatch(setChatClose(bucketId));
    };

    const selectedChat = useSelector((state: AppState) => {
        let selectedBucketId = state.chats.selectedChatBucketId;
        if (selectedBucketId && state.chats.chatBucketList[selectedBucketId]) {
            let chatUser = state.chats.chatBucketList[selectedBucketId].displayUserName;
            let chatMessages  = state.chats.chatBucketList[selectedBucketId].messages;
            return {
                userName: chatUser,
                messages: chatMessages,
                selectedBucketId: selectedBucketId
            }
        } else {
            return {
                messages: [],
                userName: '',
                selectedBucketId: ''
            };
        }
    });

    let messageItems: any = [];
    if (selectedChat.messages && selectedChat.messages.length) {
        messageItems = selectedChat.messages.slice(0).reverse().map(
            (message: IChatElements, index: number) => {
                let markIcon = require('../../../assets/images/singleCheckMark.png');
                if (message.allReceived && message.createdBy !== selectedChat.userName) {
                    markIcon = require('../../../assets/images/doubleCheckUnreadMark.png');
                }
                if (message.allRead && message.createdBy !== selectedChat.userName) {
                    markIcon = require('../../../assets/images/doubleCheckReadMark.png');
                }
                return (
                    <div key={index} style={{ padding: '12px 12px 0' }}>
                        <div className='chatMessageViewContainer'>
                            {message.createdBy !== selectedChat.userName && (
                                <div className='spacer' />
                            )}
                            <div
                                className={
                                    message.createdBy === selectedChat.userName
                                        ? 'userMsg'
                                        : 'adminMsg'
                                }
                            >
                                {message.text[0]}
                            </div>
                            {message.createdBy === selectedChat.userName && (
                                <div className='spacer' />
                            )}
                        </div>
                        <div
                            style={
                                message.createdBy !== selectedChat.userName
                                    ? { float: 'right', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }
                                    : {}
                            }
                        >
                            <div className='chatDate'>
                                { formatDateForOrderList(message.created ? message.created : new Date())}
                            </div>
                            {
                                message.createdBy !== selectedChat.userName && (
                                    <img
                                        className='checkIcon'
                                        src={markIcon}
                                        alt=''
                                    />
                                )
                            }
                        </div>
                    </div>
                );
            },
        );
    }

    // scroll to bottom
    const statusEndRef = useRef<HTMLDivElement>(null);
    const scrollToBottom = () => {
        if (statusEndRef.current !== null) {
            statusEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    useEffect(() => {
        scrollToBottom();
    }, [messageItems]);


    return (
        <div className='chatMessagesViewContainer'>
            { messageItems.length > 0 && (
                <div className='topHeader'>
                    <span style={{display: 'flex', alignItems: 'center'}}>
                        <div className='avatar' />
                        <span>{selectedChat.userName}</span>
                    </span>
                    <span className='deleteChatIcon' onClick={() => onChatCloseClick(selectedChat.selectedBucketId)}>
                        <FontAwesomeIcon
                            className="cursorPointer"
                            icon="trash"
                            color="crimson"
                        />
                    </span>
                </div>
            )}

            <div className='messageItemsWrapper'>
                {messageItems}
                <div
                    style={{ float: 'left', clear: 'both' }}
                    ref={statusEndRef}
                />
            </div>
        </div>
    );
};

export default ChatMessagesView;
