import crypto from "crypto";
import {argon2id, argon2Verify} from "hash-wasm";

const ALGORITHM = "aes-256-gcm"; // More secure than CBC
const SALT_LENGTH = 16; // Length of salt
const IV_LENGTH = 12; // Recommended for AES-GCM
const KEY_LENGTH = 32; // AES-256 requires a 32-byte key

/**
 * Derives a 256-bit encryption key from a password using Argon2
 * @param password - User's password
 * @param salt - Randomly generated salt
 * @returns A 32-byte encryption key
 */
async function deriveKey(password: string, salt: Buffer): Promise<Buffer> {
    return argon2id({  password: password,
        salt, // salt is a buffer containing random bytes
        parallelism: 1,
        iterations: 2,
        memorySize: 512, // use 512KB memory
        hashLength: KEY_LENGTH, // output size = 32 bytes
        outputType: "binary"
    });
}

/**
 * Encrypts a text using AES-256-GCM with a key derived from Argon2
 * @param text - The plaintext data
 * @param password - The user's password
 * @returns An object containing encrypted data, salt, IV, and authentication tag
 */
export async function encrypt(text: string, password: string) {
    const salt = crypto.randomBytes(SALT_LENGTH);
    const iv = crypto.randomBytes(IV_LENGTH);
    const key = await deriveKey(password, salt);
    console.log(key);
    const cipher = crypto.createCipheriv(ALGORITHM, key, iv);
    let encrypted = cipher.update(text, "utf8", "hex");
    encrypted += cipher.final("hex");

    const authTag = cipher.getAuthTag(); // Get authentication tag for AES-GCM

    return {
        encryptedData: encrypted,
        salt: salt.toString("hex"),
        iv: iv.toString("hex"),
        authTag: authTag.toString("hex"),
    };
}

/**
 * Decrypts AES-256-GCM encrypted data
 * @param encryptedText - The encrypted data
 * @param password - The user's password
 * @param saltHex - The salt used in key derivation (hex format)
 * @param ivHex - The IV used in encryption (hex format)
 * @param authTagHex - The authentication tag (hex format)
 * @returns The decrypted plaintext
 */
export async function decrypt(
    encryptedText: string,
    password: string,
    saltHex: string,
    ivHex: string,
    authTagHex: string
): Promise<string> {
    try {
        const salt = Buffer.from(saltHex, "hex");
        const iv = Buffer.from(ivHex, "hex");
        const authTag = Buffer.from(authTagHex, "hex");
        const key = await deriveKey(password, salt);

        const decipher = crypto.createDecipheriv(ALGORITHM, key, iv);
        decipher.setAuthTag(authTag); // Set authentication tag for AES-GCM

        let decrypted = decipher.update(encryptedText, "hex", "utf8");
        decrypted += decipher.final("utf8");

        return decrypted;
    } catch (error) {
        console.log(error);
        return ''
    }
}

export async function isValidPassword(
    encryptedText: string,
    password: string,
): Promise<boolean> {
    try {
        console.log(encryptedText)
        const isMatched = await argon2Verify({
            password: password,
            hash: encryptedText,
        });
        return isMatched;
    } catch(err) {
        console.log(err)
       return false
    }
}
