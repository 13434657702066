import React from "react";
import {ChangeEvent} from 'react';
import { connect } from "react-redux";

// local component and asset imports
import logo from "../../../assets/images/vitusLogo.png";
import "./Login.scss";
import Input from "../../UI/Input/Input";
import Spinner from "../../UI/Spinner/Spinner";
import { updateObject, checkValidity } from "../../../shared/utility";
import {Auth} from "../../../store/types/Auth";
import {AppState} from "../../../store/configureStore";
import {ThunkDispatch} from "redux-thunk";
import {AuthActions} from "../../../store/types/authActionTypes";
import * as actions from "../../../store/actions";
import motif from "../../../assets/images/loginMotif.png";


type input = {
    elementType: string,
    elementConfig: {
        type: string,
        label: string
    },
    value: string,
    validation: {
        required?: boolean,
        isEmail?: boolean,
        minLength?: number
    },
    valid: boolean,
    touched: boolean,
    autoFocus: boolean
}

type State = {
    loginForm: {
        [key: string]: input
    },
    formIsValid: boolean,
    showForm: boolean
}

type Props = LinkDispatchProps & LinkStateProps;

export class Login extends React.Component<Props, State> {

    state: Readonly<State> = {
        // loginForm object is used for form definition  and form validation
        loginForm: {
            userEmail: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    label: 'Email Address',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                autoFocus: false

            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    label: 'Password',
                },
                value: '',
                validation: {
                    required: true,
                },
                valid: false,
                touched: false,
                autoFocus: false
            }
        },
        formIsValid: false,
        showForm: false,
    };

    loginEventHandler = (event: any) => {
        event.preventDefault();
        const formData: any = {};
        for (let formElementIdentifier in this.state.loginForm) {
            formData[formElementIdentifier] = this.state.loginForm[formElementIdentifier].value;
        }

        this.props.onAuth(formData['userEmail'], formData['password']);

    };

    loginFromBackofficeTokenClicked = (event: any) => {
        event.preventDefault();
        navigator.clipboard.readText()
            .then(async text => {
                this.props.loginViaTokenString(text);
            })
            .catch(err => {
                // maybe user didn't grant access to read from clipboard
                console.log('Something went wrong', err);
            });

    };

    inputChangedHandler = (event: any, inputIdentifier: string) => {
        const updatedLoginFormElement = updateObject(this.state.loginForm[inputIdentifier], {
            value: event.target.value,
            valid: checkValidity(event.target.value, this.state.loginForm[inputIdentifier].validation),
            touched: true
        });
        const updatedLoginForm = updateObject(this.state.loginForm, {
            [inputIdentifier]: updatedLoginFormElement
        });
        let formIsValid = true;
        for (let inputIdentifier in updatedLoginForm) {
            formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({
            loginForm: updatedLoginForm,
            formIsValid: formIsValid
        });
    };

    async componentDidMount() {
        /*let data = await loginWithCsafe();
        console.log(data);
        this.props.onAuthWithCsafe(data);*/
    }


    render() {
        // showing error message for unauthorised login credentials

        let errorMsg = null;
        if (this.props.error) {
            errorMsg = ( <span className="errorMsg">{this.props.error}</span>);
        }
        return (
            <div className="loginContainer">
                <div className="container">
                    <div className={"left"}>
                        <div className="loginForm">

                            <div className="header">
                                <img src={logo} alt="Logo"/>
                            </div>

                            {errorMsg}

                            { this.state.showForm && (
                                <form className="loginForm">

                                    <Input
                                        elementType={this.state.loginForm.userEmail.elementType}
                                        elementConfig={this.state.loginForm.userEmail.elementConfig}
                                        autoFocus={this.state.loginForm.userEmail.autoFocus}
                                        value={this.state.loginForm.userEmail.value}
                                        changed={(event: ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'userEmail')}
                                        shouldValidate={this.state.loginForm.userEmail.validation}
                                        touched={this.state.loginForm.userEmail.touched}
                                        invalid={this.state.loginForm.userEmail.valid}
                                    />
                                    <Input
                                        elementType={this.state.loginForm.password.elementType}
                                        elementConfig={this.state.loginForm.password.elementConfig}
                                        autoFocus={this.state.loginForm.password.autoFocus}
                                        value={this.state.loginForm.password.value}
                                        changed={(event: ChangeEvent<HTMLInputElement>) => this.inputChangedHandler(event, 'password')}
                                        shouldValidate={this.state.loginForm.password.validation}
                                        touched={this.state.loginForm.password.touched}
                                        invalid={this.state.loginForm.password.valid}
                                    />
                                    {this.props.loading ? <Spinner /> : null}
                                    <button
                                        className={this.props.loading ? "loginBtnDisabled" : "loginBtn"}
                                        onClick={(event) => this.loginEventHandler(event)}
                                        disabled={!this.state.formIsValid || this.props.loading}
                                    >
                                        Login
                                    </button>
                                    <div className={"backDiv"}>
                                        <button
                                            className='backBtn'
                                            disabled={this.props.loading}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                this.setState({
                                                    showForm: false,
                                                });
                                            }}
                                        >
                                            Zurück
                                        </button>
                                    </div>
                                </form>
                            )}

                            {!this.state.showForm && (<div>
                                <div className={"vitusSubmitDiv"}>
                                    <button
                                        className='vitusSubmit'
                                        disabled={this.props.loading}
                                        style={{cursor: 'pointer', width: '280px'}}
                                        onClick={this.loginFromBackofficeTokenClicked}
                                    >
                                        Login mit VITUS Token
                                    </button>

                                </div>
                                <div className={"loginWithEmailDiv"}>
                                    <button
                                    className='loginWithEmailBtn'
                                    disabled={this.props.loading}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                            this.setState({
                                                showForm: true,
                                            });
                                        }}
                                    >
                                        Login mit E-Mail Adresse
                                    </button>
                                </div>
                            </div>)}
                        </div>
                    </div>
                    <div className={"right"}>
                        <img className={"motif"}  src={motif} alt="login motif"/>
                    </div>

                </div>
            </div>
        )
    }
}

interface LinkStateProps {
    error: Auth['error'],
    loading: Auth['loading'],
    loginToken: Auth['loginToken']
}
interface LinkDispatchProps {
    onAuth: (email: string, password: string) => void;
    onAuthWithCsafe: (data: any) => void;
    loginViaTokenString: (token: string) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
    error: state.auth.error,
    loading: state.auth.loading,
    loginToken: state.auth.loginToken
});

const mapDispatchToProps = ( dispatch: ThunkDispatch<any, any, AuthActions> ): LinkDispatchProps => ({
    onAuth: (email, password) => { dispatch(actions.auth(email, password)); },
    onAuthWithCsafe: (data) => {dispatch(actions.authWithCsafe(data))},
    loginViaTokenString: (token) => { dispatch(actions.loginViaTokenString(token)); }

});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
