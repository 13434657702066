import React, {useState} from 'react';
import Modal from "../Modal/Modal";
import logo from "../../../assets/images/vitusLogo.png";
import * as FileSaver from "file-saver";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import * as actions from "../../../store/actions/index";
import Input from "../Input/Input";
import "./BackupKey.scss"
import harddriveIcon from "../../../assets/images/harddrive-icon.png";
import cloudUploadIcon from "../../../assets/images/cloudupload-icon.png";

interface IProps {
    show: boolean;
    hide: () => void;
}

const BackupKey:React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const [showPasswordWindow, setShowPasswordWindow] = useState(false);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState('');
    const privateKeyBackupMsg: string = useSelector((state: AppState) => state.auth.backupKeyMsg);

    const isUploadedSuccessfully = (privateKeyBackupMsg.length > 0);

    const allOrders = useSelector((state: AppState) => state.orders.allOrders);
    // ======= Backup key view methods =========
    const backupKey = () => {
        localStorage.setItem('isBackupDone', '1');
        let key: any = localStorage.getItem('privateKey');
        let file = new File([key], "privateKey.key", {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(file);
        if (Object.keys(allOrders).length === 0) {
            dispatch(actions.getAllOrders() as any);
            // dispatch(actions.getChatList() as any);
        }
        props.hide();
    };

    const backupKeyCloud = () => {
        let key: any = localStorage.getItem('privateKey');
        setError('');
        if (!key) {
            setError('Privater Schlüssel nicht gefunden');
            return;
        }

        if (password.length) {
            if (password !== repeatPassword) {
                setError('Passwort stimmt nicht überein');
            } else {
                dispatch(actions.storeKeyOnCloud(key, password))
            }
        } else {
            setError('bitte Passwort angeben')
        }
    };

    return (
        <Modal
            show={props.show}
            modalClosed={props.hide}
        >
            <div className="modalContainer backup-key">
                <div className="header">
                    <img src={logo} alt="Logo" />
                    <h4 className="title">
                        Schlüssel sichern
                    </h4>
                </div>

                {error.length > 0  && (
                    <div className={'error'}>
                        {error}
                    </div>
                )}
                {isUploadedSuccessfully &&
                     <div style={{
                         padding: 8,
                         color: "green",
                         justifyItems: 'center',
                     }}>
                         {privateKeyBackupMsg}
                         <p>Sie können den Schlüssel nun auch auf einem anderen Rechner oder Browser aus der Cloud laden, um ihn auf mehreren Geräten zu nutzen.'</p>

                         <button className={'cancel'} onClick={ () => {
                             dispatch(actions.setBackupKeyMsg(''))
                             props.hide()
                         }}>schließen
                         </button>
                     </div>

                }

                {!showPasswordWindow && !isUploadedSuccessfully &&
                    <div>
                        <div className={'row'}>

                            <div className="selectBox">
                                <img className={'icon'} src={harddriveIcon} alt="cloud icon" />

                                <div className="headline">
                                    Festplatte
                                </div>
                                <div className="desc">
                                    Der Schlüssel wird als Datei exportiert
                                </div>
                                <div className={'action'}>

                                    <a
                                        className={'button'}
                                        onClick={backupKey}
                                    >
                                        Schlüssel exportiert
                                    </a>
                                </div>
                            </div>

                            <div className="selectBox">
                                <img className={'icon'} src={cloudUploadIcon} alt="cloud icon" />

                                <div className="headline">
                                    Cloud
                                </div>

                                <div className="desc">
                                    Schlüssel in Cloud hochladen
                                </div>

                                <div className={'action'}>
                                    <a onClick={() => {
                                        setShowPasswordWindow(true)
                                    }}
                                       className={'button'}>Schlüssel hochladen</a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {showPasswordWindow && !isUploadedSuccessfully &&
                    <div className={'column'}>
                        <p>
                            Bitte legen Sie ein Passwort fest, um den Schlüssel zu sichern, bevor er in die Cloud hochgeladen wird. Dieses Passwort wird benötigt, um den Schlüssel später aus der Cloud zu laden.
                        </p>
                        <p>
                            ⚠️ Wichtig: Es gibt keine “Passwort vergessen”-Option. Wenn Sie das Passwort verlieren, ist auch der Schlüssel unwiederbringlich verloren.
                        </p>
                        <Input
                            elementType={'password'}
                            elementConfig={{
                                type: 'password',
                                label: 'password',
                                options: undefined
                            }}
                            value={password}
                               changed={function (event: any): void {
                                setPassword(event.target.value);
                            }}
                            invalid={false}
                            shouldValidate={ {required: true} }
                            touched={false}
                            autoFocus={false}
                          >

                        </Input>

                        <Input
                            elementType={'password'}
                            elementConfig={{
                                type: 'password',
                                label: 'Passwort wiederholen',
                                options: undefined
                            }}
                            value={repeatPassword}
                            changed={function (event: any): void {
                                setRepeatPassword(event.target.value);
                            }}
                            invalid={false}
                            shouldValidate={ {required: true} }
                            touched={false}
                            autoFocus={false}
                        >

                        </Input>

                        <div className={"column actions"}>

                            <button  onClick={async () => {
                                backupKeyCloud();
                            }}>
                                Schlüssel in Cloud hochladen
                            </button>

                            <button className={'cancel'} onClick={async () => {
                                setShowPasswordWindow(false)
                                setPassword('')
                            }}>Züruck
                            </button>
                        </div>
                    </div>
                }

            </div>
        </Modal>
    );
};

export default BackupKey;
