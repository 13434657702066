import NodeRSA from 'node-rsa';
import * as crypto from 'crypto';
import * as callSocketApi from "./socketApi";

export interface RSAKey {
    privateKey: string;
    publicKey: string;
}

export const getPrivatePEMFromKey = (key: string) => {
    let result = '-----BEGIN RSA PRIVATE KEY-----\n';
    let keyPos = 0;
    while (keyPos < key.length) {
        result += key.substring(keyPos, keyPos + 64);
        result += '\n';
        keyPos += 64;
    }
    result += '-----END RSA PRIVATE KEY-----\n';
    return result;
};

export const getPublicPEMFromKey = (key: string) => {
    let result = '-----BEGIN RSA PUBLIC KEY-----\n';
    let keyPos = 0;
    while (keyPos < key.length) {
        result += key.substring(keyPos, keyPos + 64);
        result += '\n';
        keyPos += 64;
    }

    result += '-----END RSA PUBLIC KEY-----\n';

    return result;
};

export const checkRSAKey = (privateKey: string) => {
    try {

        let rsa = new NodeRSA(
            Buffer.from(privateKey, 'base64'),
            'pkcs1-private-der',
            {environment: 'browser'}
        );

        if (rsa) {
            // TODO: save the keys in Redux
            getPrivatePEMFromKey(privateKey);
            getPublicPEMFromKey(rsa.exportKey('pkcs1-public-der').toString('base64'));
        }
        return true;
    } catch (ex) {
        console.log('ex', ex);
        return false;
    }
};

export const getPublicRSAKeyDer = (privateKey: string): string | null => {
    let rsa = new NodeRSA(
        Buffer.from(privateKey, 'base64'),
        'pkcs1-private-der',
    );
    if (rsa) {
        try {
            return rsa.exportKey('pkcs1-public-der').toString('base64');
        } catch (ex) {
            return null;
        }
    }
    return null;
};

export const addPrivateStartEndLine = (string: any) => {
    return `-----BEGIN RSA PRIVATE KEY-----\n${string}\n-----END RSA PRIVATE KEY-----`;
};

export const privateDecryptFromBase64 = (
    base64Data: string,
    privateKey: string,
): Buffer => {
    return crypto.privateDecrypt(privateKey, Buffer.from(base64Data, 'base64'));
};

export const decryptAes = (key: Buffer, iv: Buffer, data: Buffer): Buffer => {
    let decipher = crypto.createDecipheriv('aes-256-ctr', key, iv);
    return Buffer.concat([decipher.update(data), decipher.final()]);
};

export const randomTransactionId = (): string => {
    return crypto.randomBytes(16).toString('base64');
};

export const createAesCtrNonce = (): Buffer => {
    let bytes = crypto.randomBytes(8);
    return Buffer.concat([bytes, new Buffer([0, 0, 0, 0, 0, 0, 0, 0])]);
};

export const encryptAes = (key: Buffer, iv: Buffer, data: Buffer): Buffer => {
    let cipher = crypto.createCipheriv('aes-256-ctr', key, iv);
    return Buffer.concat([cipher.update(data), cipher.final()]);
};


export const checkPrivateKey = (privateKey: string) => {
    return new Promise(async (resolve) => {
        let data = {
            pharmacyIdf: null,
            pharmacyCity: null,
            pharmacyZip: null,
            isValid: false
        };
            if (await checkRSAKey(privateKey)) {
                let result = await callSocketApi.getOwnPharmacyData();
                // console.log(result);
                if (result.errorCode === 0 && result.pharmacy) {
                    data = {
                        pharmacyIdf: result.pharmacy.idf,
                        pharmacyCity: result.pharmacy.city,
                        pharmacyZip: result.pharmacy.zip,
                        isValid: false
                    };
                    if (result.pharmacy.publicKey === getPublicRSAKeyDer(privateKey)) {
                        data.isValid = true;
                        resolve(data);
                    } else {
                        resolve(data);
                    }
                }

            } else {
                resolve(data);
            }

    })
};

export const keyGenerator = () => {
    return new Promise<RSAKey>((resolve, reject) => {
        let rsa = new NodeRSA();
        let key = (rsa.generateKeyPair(2048, 65537));
        resolve( {
            privateKey: key.exportKey('pkcs1-private-der').toString('base64'),
            publicKey: key.exportKey('pkcs1-public-der').toString('base64')
        });
    });
};
