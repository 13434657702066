import { IChatElements } from '../store/types/Chat';
import {
    addPrivateStartEndLine,
    createAesCtrNonce,
    decryptAes, encryptAes,
    privateDecryptFromBase64,
} from './securityHelper';
import { store } from '../store/configureStore';
import { getPrivateKey } from './csafeSocketApi';
import * as callSocketApi from "./socketApi";
import {shortId} from "./utility";
import * as actions from "../store/actions/index";

export const getMessageFromData = async (
    data: any,
    encryptedBase64Aes: string,
    bucketId: string,
) => {
    let privateKey = await getPrivateKey();
    let decryptAesKey = privateDecryptFromBase64(
        encryptedBase64Aes,
        addPrivateStartEndLine(privateKey),
    );
    let chatMessage: IChatElements = {
        elementId: data.elementId,
        elementType: data.elementType,
        allRead: false,
        allReceived: false,
        text: [],
    };
    if (data.subElements) {
        // allRead 0 = false, 1 = true
        chatMessage.allRead = data.allRead;
        // allReceived 0 = false, 1= true
        chatMessage.allReceived = data.allReceived;
        // createdAt
        chatMessage.created = new Date(data.created);
        // elementType
        chatMessage.elementType = data.elementType;
        // createdBy
        chatMessage.createdBy = getMessageSenderName(bucketId, data.createdBy);

        for (let i = 0; i < data.subElements.length; i++) {
            if (chatMessage.elementType === 'message') {
                let decryptContent = decryptAes(
                    decryptAesKey,
                    Buffer.from(data.subElements[i].iv, 'base64'),
                    Buffer.from(data.subElements[i].encryptedValue, 'base64'),
                );
                if (decryptContent) {
                    chatMessage.text.push(decryptContent.toString('utf8'));
                }
            }
        }
    }

    return chatMessage;
};

export const getMessageSenderName = (bucketId: string, id: string) => {
    let chatBucketKeys: any;
    if (store.getState().chats.chatBucketList[bucketId]) {
        chatBucketKeys = store.getState().chats.chatBucketList[bucketId].encryption.v1.keys;
    }
    for (let key in chatBucketKeys) {
        if (chatBucketKeys.hasOwnProperty(key)) {
            if (key === id) {
                return store.getState().chats.chatBucketList[bucketId].displayPharmacyName;
            } else {
                return store.getState().chats.chatBucketList[bucketId].displayUserName;
            }
        }
    }

    return 'Unknown';
};

export const sendChatMessage = async (bucketId: string, textMsg: string) => {
    let pharmacyId: any = store.getState().auth.pharmacyId,
        selectedChatBucket = store.getState().chats.chatBucketList[bucketId],
        encryptedBase64Aes = selectedChatBucket.encryption.v1.keys[pharmacyId].key;
    let newMessage = await createMessage(bucketId, textMsg, encryptedBase64Aes);
    let result = await callSocketApi.sendNewChatMessage(newMessage);
    if (result.errorCode !== 0) {
        console.log('could not send message');
        console.log(result);
    } else {
    }
};


export const createMessage = async (
    bucketId: string,
    message: string,
    encryptedBase64Aes: string,
) => {
    let iv = createAesCtrNonce();
    let privateKey = await getPrivateKey();
    let decryptedAesKey = privateDecryptFromBase64(encryptedBase64Aes, addPrivateStartEndLine(privateKey));

    try {
        let encryptedContent = encryptAes(
            decryptedAesKey,
            iv,
            Buffer.from(message, 'utf-8'),
        );


        const chatMessage = {
            clientTransactionId: shortId(9),
            bucketId: bucketId,
            subElements: [
                {
                    type: 'message',
                    encryptedValue: encryptedContent.toString('base64'),
                    iv: iv.toString('base64'),
                },
            ],
        };

        return chatMessage;
    } catch (ex) {
        console.log('could not encrypt new message');
    }
};


export const analyseNewChatMessage = async (data: any) => {

    let pharmacyChat = store.getState().chats.chatBucketList[data.bucketId];
    let isChatOpen = store.getState().chats.selectedChatBucketId === data.bucketId;
    let pharmacyId: any = store.getState().auth.pharmacyId;
    if (pharmacyChat) {
        // bucket exists
        let isPrevMessagesLoaded = pharmacyChat.isMessagesLoaded;
        if (isPrevMessagesLoaded) {
            // bucket exists & prev messages loaded
            let messageData = await getMessageFromData(
                data.element,
                pharmacyChat.encryption.v1.keys[pharmacyId].key,
                data.bucketId,
            );
            store.dispatch(actions.addIncomingChatMessage(data.bucketId, messageData));
        } else {
            // bucket exist but previous message not loaded
            let result = await callSocketApi.chatGetMessages(data.bucketId);
            if (result.errorCode === 0) {
                let messages: IChatElements[] = [];
                for (let key in result.elements) {
                    if (result.elements.hasOwnProperty(key)) {
                        let encryptedAesKeys_Base64 = pharmacyChat.encryption.v1.keys[pharmacyId].key;
                        let messageData = await getMessageFromData(
                            result.elements[key],
                            encryptedAesKeys_Base64,
                            data.bucketId
                        );
                        messages.push(messageData);
                    }
                }
                store.dispatch(actions.setChatMessages( data.bucketId, messages, data.element.elementId ));
            }
        }

        // If it is from client we mark message as read/received
        if (parseInt(data.element.createdBy, 10) !== pharmacyId) {
            if (isChatOpen) {
                await markChatMessage(data.bucketId, data.element.elementId, 0);
                await markChatMessage(data.bucketId, data.element.elementId, 1);
            } else {
                store.dispatch(actions.addChatBadgeCount(data.bucketId));
                await markChatMessage(data.bucketId, data.element.elementId, 0);
            }
        }
    } else {
        // bucket dont exist
        await getChatBucket(data, pharmacyId);
    }
};

const getChatBucket = async (data: any, pharmacyId: any) => {
    // new message
    let result = await callSocketApi.getChat( data.bucketId);
    if (result && result.errorCode === 0) {
        store.dispatch(actions.addNewChatBucket(result.bucket));
        store.dispatch(actions.addChatBadgeCount(data.bucketId));
        store.dispatch(actions.getChatMessages( data.bucketId, result.bucket.encryption.v1.keys) as any);
        // If it is from client we mark message as received
        if (parseInt(data.element.createdBy, 10) !== pharmacyId) {
            await markChatMessage(data.bucketId, data.element.elementId, 0);
        }
    }
};

export const analyseIncomingChatNotification = (data: any) => {
    if (data.activityType === 101) {
        store.dispatch(actions.updateChatMessageReadReceipts(
            data.bucketId,
            data.element.elementId,
            data.element.markType === 0 ? 'allReceived' : 'allRead'
        ));
    }
};

export const markChatMessage = async (bucketId: string, elementId: number, type: number) => {
    let clientTransactionId = shortId(9);
    let result = await callSocketApi.chatMarkMessage( {
        bucketId,
        elementId,
        type,
        clientTransactionId,
    });
    console.log(`mark msg ${type}`, result);
};
