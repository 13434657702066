import React from 'react';

// local imports
import {IOrder, IOrderItem} from "../../../store/types/Orders";
import OrderItem from "../OrderItem/OrderItem";
import "./OrderItemsView.scss";
import {formatDateForOrderList} from "../../../shared/utility";
import {formatPayback} from "../../../shared/orderHelper";
import bwipjs from "bwip-js";
import OrderItemImage from "../OrderItem/OrderItemImage";
import * as actions from "../../../store/actions";
import {useDispatch} from "react-redux";

interface IProps {
    order: IOrder
}

const OrderItemsView: React.FC<IProps> = (props:IProps) => {
    let orderItemsListImages :any = [];
    let orderItemsListTexts :any = [];
    let ePrescription :any = [];
    let ePrescriptionCanvases = null;

    const dispatch = useDispatch();

    const onImageClick = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
       dispatch(actions.showLargeImage(e.target.toDataURL()));
    };

    setTimeout(async () => {
        if (props) {
            for (let i = 0; i < props.order.items.length; i++) {
                let orderItem = props.order.items[i];

                if (orderItem.eprescriptionText) {
                    let dataMatrixCanvas = bwipjs.toCanvas('ePrescriptionCanvas' + orderItem.itemId, {
                        bcid: 'datamatrix',
                        text: orderItem.eprescriptionText,
                        height: 40,
                        includetext: false,
                    });
                }
            }
        }

    });

    ePrescriptionCanvases = props.order.items.map((item) => {
        if (item.eprescriptionText) {
            return (
                <div style={{
                    marginTop: 8,
                    cursor: 'pointer',
                }}

                >
                    <div>
                        <span style={{backgroundColor: '#55687f', padding: 4, borderRadius: 5}}>E-Rezept</span>
                    </div>
                    <div style={{
                        backgroundColor: '#ffffff',
                        padding: 12,
                        borderRadius: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <canvas id={"ePrescriptionCanvas" + item.itemId}
                                onClick={ (e) => {
                                    onImageClick(e)
                                }}
                        >

                        </canvas>
                    </div>
                </div>
            );
        }

        if (item.type === 'jpg') {
            orderItemsListImages.push(<OrderItemImage orderItem={item} order={props.order} key={item.itemId}/>);
        }

        if (item.type === 'text') {
            orderItemsListTexts.push(<OrderItem orderItem={item} order={props.order} key={item.itemId}/>);
        }
    });

    return (
        <div className="orderItemsMainWrapper">
            <div className="orderItemsViewContainer">
                <div className="itemsContainer">
                    <div className="title" style={{userSelect: 'auto'}}>
                        VORBESTELLUNG
                    </div>
                    {props.order.paybackCustomerId &&
                    <div className="paybackContainer">
                        <img src={require('../../../assets/images/payback.png')} style={{marginRight: 8}} alt='Payback'/>{formatPayback(props.order.paybackCustomerId)}
                    </div>}
                    <div className="imagesContainer">
                        {orderItemsListImages}
                    </div>
                    <div className="ePrescriptionContainer">
                        { ePrescriptionCanvases }
                    </div>
                    {orderItemsListTexts}
                </div>
                <div className="spacer"/>
                        </div>
            <div className="orderDate">
                {formatDateForOrderList(props.order.date)}
            </div>
        </div>
    );
};

export default OrderItemsView;
