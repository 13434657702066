import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

// local imports
import './OrderList.scss';
import OrderListEntry from '../OrderListEntry/OrderListEntry';
import { AppState } from '../../../store/configureStore';
import {getOrderFromAppIdImageRequire, getStatusText} from '../../../shared/orderHelper';
import { formatDateForOrderList } from '../../../shared/utility';
import * as actions from '../../../store/actions/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ViewSelector from "../../Navigation/ViewSelector/ViewSelector";

interface IProps {}

const OrderList: React.FC<IProps> = () => {
    const dispatch = useDispatch();
    const allOrders = useSelector((state: AppState) => state.orders.allOrders);
    const selectedOrderId = useSelector(
        (state: AppState) => state.orders.selectedOrderId,
    );
    const ordersIdList = useSelector(
        (state: AppState) => state.orders.sortedOrderIdList,
    );
    const multiSelectedOrderIds = useSelector(
        (state: AppState) => state.orders.multiSelectedOrderIds,
    );
    const [isDeleteEnable, setIsDeleteEnable] = useState(false);

    const resetDeleteState = () => {
        setIsDeleteEnable(false);
        dispatch(actions.resetMultiSelectedOrderId());
    };
    const onSelectOrder = (selectedOrderId: number) => {
        dispatch(actions.orderSelected(selectedOrderId));
    };
    const showFilter = () => {
        dispatch(actions.showFilterStatus());
    };
    const onDeleteOrder = () => {
        setIsDeleteEnable(false);
        for (let i = 0; i < multiSelectedOrderIds.length; i++) {
            dispatch(actions.deleteThisOrder(multiSelectedOrderIds[i]));
        }
    };
    let orderListEntries: any = [];

    for (let i = 0; i < ordersIdList.length; i++) {
        orderListEntries.push(
            <OrderListEntry
                key={ordersIdList[i]}
                customerName={allOrders[ordersIdList[i]].user.name}
                date={formatDateForOrderList(
                    new Date(allOrders[ordersIdList[i]].timestamp),
                )}
                status={getStatusText(allOrders[ordersIdList[i]].status, true)}
                isSelected={
                    selectedOrderId === allOrders[ordersIdList[i]].orderId
                }
                onSelectOrder={() =>
                    onSelectOrder(allOrders[ordersIdList[i]].orderId)
                }
                hasImportantMessage={
                    allOrders[ordersIdList[i]].hasImportantMessage
                }
                newMessagesCount={allOrders[ordersIdList[i]].newMessagesCount}
                orderStatus={allOrders[ordersIdList[i]].status}
                isDeleteEnable={isDeleteEnable}
                orderId={allOrders[ordersIdList[i]].orderId}
                image={getOrderFromAppIdImageRequire(allOrders[ordersIdList[i]].fromAppId)}

            />,
        );
    }

    return (
        <div className="orderListContainer orderListContainer_delayed">
            <div className="orderListContent">
                <ViewSelector
                    selectionChanged={() => resetDeleteState()}
                />
                <div className="orderFilterContainer">
                    {/* ===== Delete order ======= */}
                    {multiSelectedOrderIds.length === 0 && (
                        <FontAwesomeIcon
                            className="cursorPointer"
                            icon="trash"
                            color="#3cbaa8"
                            data-tip={'Löschen'}
                            onClick={() => setIsDeleteEnable(!isDeleteEnable)}
                        />
                    )}
                    {multiSelectedOrderIds.length > 0 && isDeleteEnable && (
                        <div className="deletePromptWrapper">
                            <span className="deleteText">
                                Bestellung Löschen (
                                {multiSelectedOrderIds.length}) ?
                            </span>
                            <FontAwesomeIcon
                                className="cursorPointer deletePromptIcon"
                                icon="check"
                                color="grey"
                                onClick={() => onDeleteOrder()}
                            />
                            <FontAwesomeIcon
                                className="cursorPointer deletePromptIcon"
                                icon="times"
                                color="grey"
                                onClick={() => resetDeleteState()}
                            />
                        </div>
                    )}
                    {/* ======== Filter Order ====== */}
                    <span className="cursorPointer" onClick={showFilter}>
                        <FontAwesomeIcon
                            icon="filter"
                            color="#3cbaa8"
                            data-tip={'Anzeige filtern'}
                        />
                    </span>
                </div>
                {orderListEntries}
                <ReactTooltip
                    delayShow={1000}
                    place={'right'}
                    effect={'solid'}
                    multiline={true}
                />
            </div>
        </div>
    );
};

export default OrderList;
