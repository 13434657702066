import {
    createStore,
    combineReducers,
    applyMiddleware,
    compose,
    AnyAction,
} from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

//reducer imports
import authReducer from './reducers/auth';
import ordersReducer from './reducers/orders';
import chatReducer from './reducers/chat';
import cashRegister from "./reducers/cashRegister";

export const rootReducer = combineReducers({
    auth: authReducer,
    orders: ordersReducer,
    chats: chatReducer,
    cashRegister: cashRegister
});

export type AppState = ReturnType<typeof rootReducer>;

export const composeEnhancers =
    (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk as ThunkMiddleware<AppState, AnyAction>),
    ),
);
