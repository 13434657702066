import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Modal from '../../UI/Modal/Modal';
import logo from '../../../assets/images/vitusLogo.png';
import './PublicKeyNotFoundView.scss';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/configureStore';
import Spinner from "../../UI/Spinner/Spinner";

interface IProps {
    show: boolean;
}

const PublicKeyNotFoundView:React.FC<IProps> = (props: IProps) => {
    const authLoading = useSelector((state: AppState) => state.auth.loading);
    const rsaKeyLoading = useSelector((state: AppState) => state.auth.rsaKeyLoading);
    const isPrivateKeyValid = useSelector((state: AppState) => state.auth.isPrivateKeyValid);
    const isNewRSAKeyCreating = useSelector((state: AppState) => state.auth.rsaKeyCreating);
    const dispatch = useDispatch();
    const onFirstUseClicked = () => {
        setTimeout(() => dispatch(actions.startCreateRSAKey()), 0);
        setTimeout(() => {
            dispatch(actions.createRSAKey());
        }, 100);
    };
    const keyChangeHandler = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        let file = event.dataTransfer
            ? event.dataTransfer.files[0]
            : event.target.files[0];
        // console.log(file);
        if (file) {
            let reader = new FileReader();
            reader.onload = onReaderLoad;
            reader.readAsText(file);
        }
    };

    const onReaderLoad = async (event: any) => {
        // console.log(event.target.result);
        let privateKey = event.target.result;
        dispatch(actions.checkPrivateKeyAndLoadOrders(privateKey, true, false));
    };
    // The function below helps bypass the problem where onChange event doesn't trigger for same file
    const onInputClick = (event: any) => {
        event.target.value = '';
    };
    let keyFileInput = (
        <input
            id="myInput"
            type="file"
            onChange={e => keyChangeHandler(e)}
            onClick={e => onInputClick(e)}
        />
    );
    return (
        <React.Fragment>
            <Modal
                show={props.show && !rsaKeyLoading}
                modalClosed={() => console.log('close modal')}
            >
                {authLoading ? (
                    'Loading ....'
                ) : (
                    <div className="modalContainer">
                        {
                            isNewRSAKeyCreating ? (
                                <Spinner />
                            ) : (
                                <React.Fragment>
                                    <div className="header">
                                        <img src={logo} alt="Logo" />
                                        <h4 className="title">
                                            Es existiert kein privater Schlüssel
                                        </h4>
                                    </div>
                                    {!isPrivateKeyValid ? (
                                        <div className="errorMsg">
                                            Privater Schlüssel ist fehlerhaft. Bitte importieren
                                            Sie den passenden.
                                        </div>
                                    ) : null}
                                    <p className="description">
                                        Wählen Sie nachfolgend die Art der Einrichtung aus:
                                    </p>

                                    <div className="selectBox" onClick={onFirstUseClicked}>
                                        <div className="headline">Erstnutzung</div>
                                        <div className="desc">
                                            Wählen Sie diese Option, wenn Sie 'deine Apotheke' zum ersten Mal nutzen. Dabei wird ein <b>neuer</b> privater Schlüssel erstellt.
                                        </div>
                                    </div>
                                    <div className="selectBox">
                                        <div className="headline">
                                            Schlüssel neu importieren
                                        </div>
                                        <div className="desc">
                                            Wählen Sie diese Option, wenn Sie einen privaten Schlüssel besitzen. Der Schlüssel wird importiert.
                                        </div>
                                        {keyFileInput}
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </div>
                )}
            </Modal>

        </React.Fragment>
    );
};

export default PublicKeyNotFoundView;
