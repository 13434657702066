import { OrdersActions } from '../types/ordersActionTypes';
import {ILocalImageData, IOrder, IOrderMessage, OrdersInterface, TSortMode} from '../types/Orders';
import { updateObject } from '../../shared/utility';
import * as actionTypes from '../actions/actionTypes';
import {createAdditionalMessages} from "../../shared/orderHelper";
import {store} from "../configureStore";
import * as actions from "../actions/index";

const initialState: OrdersInterface = {
    allOrders: [],
    selectedOrderId: -1,
    orderImagesDownloading: false,
    imagesByFileToken: {},
    sortedOrderIdList: [],
    dontFilterOrderId: -1,
    sortMode: 'messageDate',
    sortAscending: false,
    activeOrdersFilters: [1, 20, 21, 30, 40, 41, 42],
    typedTexts: {},
    showChangeStatus: false,
    showFilterStatus: false,
    changeStatusInformUser: false,
    multiSelectedOrderIds: [],
    showLargeImage: false,
    loading: false,
    error: '',
    orderStatusChangeLoading: false,
    orderStatusChangeError: '',
    loadedOptionalFilters: [],
};

// ======== Helper function for sorting orderList =======

const getSortedOrderList = (state: OrdersInterface, orders: {[id: number]: IOrder}, sortMode: TSortMode, sortAscending: boolean, sortFilters: number[]): number[] => {
    let orderArray: IOrder[] = [];
    Object.keys(orders).forEach(function(key: string) {
        let order = orders[Number(key)];
        if (sortFilters.indexOf(order.status) > -1 || order.newMessagesCount > 0 || order.orderId === state.dontFilterOrderId) {
            orderArray.push(order);
        }
    });

    let before = 1;
    let behind = -1;
    if (sortAscending) {
        before = -1;
        behind = 1;
    }
    orderArray.sort((order1: IOrder, order2: IOrder) => {
        switch (sortMode) {
            case 'orderDate':
                return (order1.date < order2.date) ? before : behind;
            case 'messageDate': //if no messages or messages date use order date
                return ((order1.messages[order1.messages.length - 1].date || order1.date) < (order2.messages[order2.messages.length - 1].date || order2.date)) ? before : behind;
        }
        return 0;
    });

    return orderArray.map(order => order.orderId);
};

// ===== END Helper function =======

const initLoadOrder = (state: OrdersInterface, action: any) => {
    return updateObject(state, { loading: true, error: '' });
};

const loadOrderSuccess = (state: OrdersInterface, action: any) => {
    let orders: { [id: number]: IOrder } = {};
    for (let i = 0; i < action.allOrders.length; i++) {
        orders[action.allOrders[i].orderId] = Object.assign(
            {},
            action.allOrders[i],
        );
    }
    return updateObject(state, {
        loading: false,
        allOrders: orders,
        sortedOrderIdList: getSortedOrderList(state, orders, state.sortMode, state.sortAscending, state.activeOrdersFilters)
    });
};

const loadOrderFail = (state: OrdersInterface, action: any) => {
    return updateObject(state, { loading: false, error: action.error });
};

const orderSelected = (state: OrdersInterface, action: any) => {
    setTimeout(() => {
        store.dispatch(actions.setDontFilterOrderId(action.selectedOrderId));
        // notificationController.removeNotifications(action.payload.orderId);
        // notificationController.setRepeatTimer();
    }, 0);
    return updateObject(state, { selectedOrderId: action.selectedOrderId });
};

const initOrderImagesDownload = (state: OrdersInterface, action: any) => {
    return updateObject(state, { orderImagesDownloading: true });
};

const orderImagesDownloadDone = (state: OrdersInterface, action: any) => {
    let order: IOrder = action.order;
    order.imageStatus = 'loaded';
    return updateObject(state, { allOrders: {...state.allOrders, [order.orderId]: order} });
};

const setOrderImageData = (state: OrdersInterface, action: any) => {
    let orders = {...state.allOrders};
    let items = orders[action.orderId].items;
    for (let i = 0; i < items.length; i++) {
        if (items[i].itemId === action.orderItemId) {
            items[i].imageData = action.imageData;
        }
    }
    return updateObject(state, { allOrders: orders, orderImagesDownloading: false });
};

const setOrderData = (state: OrdersInterface, action: any) => {
    let order: IOrder = action.order;
    return updateObject(state, {
        allOrders: { ...state.allOrders, [action.order.orderId]: order },
    });
};

const imageByFileTokenInit = (state: OrdersInterface, action: any) => {
    let fileToken = action.fileToken;
    let imageData: ILocalImageData = Object.assign({}, state.imagesByFileToken[fileToken]);
    imageData.loadStatus = 'loading';
    return updateObject(state, {imagesByFileToken: {...state.imagesByFileToken, [fileToken]: imageData}});
};

const imageByFileTokenDone = (state: OrdersInterface, action: any) => {
    let fileToken = action.fileToken;
    let imageData: ILocalImageData = Object.assign({}, state.imagesByFileToken[fileToken]);
    imageData.loadStatus = 'loaded';
    imageData.imageData = action.imageData;
    return updateObject(state, {imagesByFileToken: {...state.imagesByFileToken, [fileToken]: imageData}});
};

const imageByFileTokenFailed = (state: OrdersInterface, action: any) => {
    let fileToken = action.fileToken;
    let imageData: ILocalImageData = Object.assign({}, state.imagesByFileToken[fileToken]);
    imageData.loadStatus = 'loadFailed';
    return updateObject(state, {imagesByFileToken: {...state.imagesByFileToken, [fileToken]: imageData}});
};

const setAdditionalOrderMessages = (state: OrdersInterface, action: any) => {
    let orders = state.allOrders;
    let order = orders[action.orderId];
    if (!order) {
        return state;
    }
    return updateObject(state, {
        allOrders: {...state.allOrders, [action.orderId]: {
            ...state.allOrders[action.orderId],
                hasImportantMessage: action.hasImportantMessage,
                additionalMessages: [...action.additionalMessages],
                newMessagesCount: action.newMessagesCount
            }}
    });
};

const orderStatusChangeStart = (state: OrdersInterface, action: any) => {
    return updateObject(state, {orderStatusChangeLoading: true, orderStatusChangeError: ''});
};

const orderStatusChangeFail = (state: OrdersInterface, action: any) => {
    return updateObject(state, {orderStatusChangeLoading: false, orderStatusChangeError: action.orderStatusChangeError});
};

const orderStatusChangeSuccess = (state: OrdersInterface, action: any) => {
    let order = state.allOrders[action.orderId];
    if (order) {
        setTimeout(() => {
           createAdditionalMessages(order);
        }, 0);
        order.messages.push({type: 'status', status: action.status, from: 'pharmacy', date: new Date()});
        order = Object.assign({}, order);
        order.status = action.status;
        return {
            ...state, orders: {
                ...state.allOrders, [action.orderId]: {
                    ...order, [action.orderId]:
                        {
                            ...state.allOrders[action.orderId],
                            messages: order.messages
                        }
                }
            }
        };
    } else {
        return state;
    }
};

const setDontFilterOrderId = (state: OrdersInterface, action: any) => {
    return updateObject(state, {dontFilterOrderId: action.dontFilterOrderId});
};

const setOrderSortMode = (state: OrdersInterface, action: any) => {
    return updateObject(state, {
        sortMode: action.sortMode,
        sortedOrderIdList: getSortedOrderList(state, state.allOrders, action.sortMode, state.sortAscending, state.activeOrdersFilters),
    });
};

const setTypedText = (state: OrdersInterface, action: any) => {
    let typedTexts = state.typedTexts;
    typedTexts[action.orderId] = action.text;
    return {...state, typedTexts: typedTexts};
};

const singleOrderDataLoaded = (state: OrdersInterface, action: any) => {
    setTimeout(() => {
        store.dispatch(actions.newMessageCountChanged());
    }, 0);
    let oldOrder: IOrder = state.allOrders[action.order.orderId];
    if (!oldOrder) {
        // TODO:
        // setImmediate(() => {
        //     orderController.store.dispatch(view_NewOrder(action.payload));
        // });
    } else {
        let order: IOrder = action.order;
        if (order.messages.length > 0 && order.messages.length !== oldOrder.messages.length && order.status > 1) {
            if (order.messages[order.messages.length - 1].from !== 'pharmacy') {
                // TODO:
                // setImmediate(() => {
                //     orderController.store.dispatch(view_NewMessage(action.payload));
                // });
            }
        }
    }
    let orders = { ...state.allOrders, [action.order.orderId]: action.order};


    return updateObject(state, {
        allOrders: orders,
        sortedOrderIdList: getSortedOrderList(state, orders, state.sortMode, state.sortAscending, state.activeOrdersFilters)
    });
};

const addPharmacyChatMessage = (state: OrdersInterface, action: any) => {
    let orders = state.allOrders;
    let order = orders[action.orderId];
    if (order) {
        order.messages.push({type: 'text', text: action.message, from: 'pharmacy', date: new Date()});
    }
    return {
        ...state,
        orders: {
            ...state.allOrders,
            [action.orderId]:
                {...state.allOrders[action.orderId],
                    messages: order.messages
                }
        }
    };
};

const orderMessageRead = (state: OrdersInterface, action: any) => {
    setTimeout(() => {
        console.log('newMessageCountChanged orderMessageRead');
        store.dispatch(actions.newMessageCountChanged());
    }, 0);
    // console.log('orderMessageRead');
    return {
        ...state, allOrders: {
            ...state.allOrders, [action.orderId]: {
                ...state.allOrders[action.orderId],
                newMessagesCount: action.newMessagesCount,
                messages: state.allOrders[action.orderId].messages.map((message: IOrderMessage, index: number) => {
                    return message.messageId === action.messageId ? { ...message, readByPharmacy: true } : message;
                })
            }
        }
    };
};

const newMessageCountChanged = (state: OrdersInterface, action: any) => {
    let ordersWithMessagesCount = 0;
    Object.keys(state.allOrders).forEach(function(key: string) {
        let order = state.allOrders[Number(key)];
        if (order.newMessagesCount > 0) {
            ordersWithMessagesCount++;
        }
    });
    // securityController.moduleBase.setBadgeCount(ordersWithMessagesCount); // TODO:
    return updateObject(state, {ordersWithMessagesCount: ordersWithMessagesCount});
};

const showChangeStatus = (state: OrdersInterface, action: any) => {
    return updateObject(state, {showChangeStatus: true, changeStatusInformUser: true});
};

const hideChangeStatus = (state: OrdersInterface, action: any) => {
    return updateObject(state, {showChangeStatus: false});
};

const showLargeImage = (state: OrdersInterface, action: any) => {
    return updateObject(state, {showLargeImage: true, largeImageData: action.imageData})
};

const hideLargeImage = (state: OrdersInterface, action: any) => {
    return updateObject(state, {showLargeImage: false});
};

const showFilterStatus = (state: OrdersInterface, action: any) => {
    return updateObject(state, {showFilterStatus: true, selectedOrderId: -1});
};

const hideFilterStatus = (state: OrdersInterface, action: any) => {
    return updateObject(state, {showFilterStatus: false});
};

const toggleActiveOrderFilter = (state: OrdersInterface, action: any) => {
    let activeOrdersFilters = [...state.activeOrdersFilters];
    let position = activeOrdersFilters.indexOf(action.position);
    if (position > -1) {
        activeOrdersFilters.splice(position, 1);
    } else {
        activeOrdersFilters.push(action.position);
        let ordersAlreadyWeRetrieved = [1, 20, 21, 30, 40, 41, 42];
        if (!ordersAlreadyWeRetrieved.includes(action.position)) {
            store.dispatch(actions.getOrdersWithFilter(action.position, state) as any);
        }
    }
    return {
        ...state,
        activeOrdersFilters: activeOrdersFilters,
        sortedOrderIdList: getSortedOrderList(state, state.allOrders, state.sortMode, state.sortAscending, activeOrdersFilters)
    };
};

const getOrdersWithFiltersSuccess = (state: OrdersInterface, action: any) => {
    let orders: { [id: number]: IOrder } = {};
    for (let i = 0; i < action.orders.length; i++) {
        orders[action.orders[i].orderId] = Object.assign(
            {},
            action.orders[i],
        );
    }
    let updatedOrders = { ...state.allOrders, ...orders};
    return updateObject(state, {
        loading: false,
        loadedOptionalFilters: [...state.loadedOptionalFilters, action.statusCode],
        allOrders: updatedOrders,
        sortedOrderIdList: getSortedOrderList(state, updatedOrders, state.sortMode, state.sortAscending, state.activeOrdersFilters)
    });
};

const addOrderForDelete = (state: OrdersInterface, action: any) => {
    let updatedOrderIds = [...state.multiSelectedOrderIds, action.orderId];
    return updateObject(state, {multiSelectedOrderIds: updatedOrderIds});
};

const removeOrderForDelete = (state: OrdersInterface, action: any) => {
    let updatedOrderIds = state.multiSelectedOrderIds.filter((id: number) => id !== action.orderId);
    return updateObject(state, {multiSelectedOrderIds: updatedOrderIds});
};

const resetMultiSelectedOrderId = (state: OrdersInterface, action: any) => {
    return updateObject(state, {multiSelectedOrderIds: []});
};

const orderDeleted = (state: OrdersInterface, action: any) => {
    let updatedOrders = {...state.allOrders};
    delete updatedOrders[action.orderId];
    return updateObject(state, {
        allOrders: updatedOrders,
        multiSelectedOrderIds: [],
        sortedOrderIdList: getSortedOrderList(state, updatedOrders, state.sortMode, state.sortAscending, state.activeOrdersFilters)
    });
};

const resetData = (state: OrdersInterface, action: any) => {
    return {
        ...state,
        allOrders: [],
        sortedOrderIdList: [],
        dontFilterOrderId: -1,
        selectedOrderId: -1,
        showFilterStatus: false,
        showOrderDeletePrompt: false,
        multiSelectedOrderIds: [],
        loadedOptionalFilters: [],
    };
};

const reducer = (state = initialState, action: OrdersActions) => {
    switch (action.type) {
        case actionTypes.INIT_LOAD_ORDER:
            return initLoadOrder(state, action);
        case actionTypes.LOAD_ORDER_SUCCESS:
            return loadOrderSuccess(state, action);
        case actionTypes.LOAD_ORDER_FAIL:
            return loadOrderFail(state, action);
        case actionTypes.ORDER_SELECTED:
            return orderSelected(state, action);
        case actionTypes.INIT_ORDER_IMAGES_DOWNLOAD:
            return initOrderImagesDownload(state, action);
        case actionTypes.ORDER_IMAGES_DOWNLOAD_DONE:
            return orderImagesDownloadDone(state, action);
        case actionTypes.SET_ORDER_IMAGE_DATA:
            return setOrderImageData(state, action);
        case actionTypes.SET_ORDER_DATA:
            return setOrderData(state, action);
        case actionTypes.IMAGE_BY_FILE_TOKEN_INIT:
            return imageByFileTokenInit(state, action);
        case actionTypes.IMAGE_BY_FILE_TOKEN_DONE:
            return imageByFileTokenDone(state, action);
        case actionTypes.IMAGE_BY_FILE_TOKEN_FAILED:
            return imageByFileTokenFailed(state, action);
        case actionTypes.SET_ADDITIONAL_ORDER_MESSAGES:
            return setAdditionalOrderMessages(state, action);
        case actionTypes.ORDER_STATUS_CHANGE_START:
            return orderStatusChangeStart(state, action);
        case actionTypes.ORDER_STATUS_CHANGE_FAIL:
            return orderStatusChangeFail(state, action);
        case actionTypes.ORDER_STATUS_CHANGE_SUCCESS:
            return orderStatusChangeSuccess(state, action);
        case actionTypes.SET_DONT_FILTER_ORDER_ID:
            return setDontFilterOrderId(state, action);
        case actionTypes.SET_ORDER_SORT_MODE:
            return setOrderSortMode(state, action);
        case actionTypes.SINGLE_ORDER_DATA_LOADED:
            return singleOrderDataLoaded(state, action);
        case actionTypes.SET_TYPED_TEXT:
            return setTypedText(state, action);
        case actionTypes.ADD_PHARMACY_CHAT_MESSAGE:
            return addPharmacyChatMessage(state, action);
        case actionTypes.ORDER_MESSAGE_READ:
            return orderMessageRead(state, action);
        case actionTypes.NEW_MESSAGE_COUNT_CHANGED:
            return newMessageCountChanged(state, action);
        case actionTypes.SHOW_CHANGE_STATUS:
            return showChangeStatus(state, action);
        case actionTypes.HIDE_CHANGE_STATUS:
            return hideChangeStatus(state, action);
        case actionTypes.SHOW_LARGE_IMAGE:
            return showLargeImage(state, action);
        case actionTypes.HIDE_LARGE_IMAGE:
            return hideLargeImage(state, action);
        case actionTypes.SHOW_FILTER_STATUS:
            return showFilterStatus(state, action);
        case actionTypes.HIDE_FILTER_STATUS:
            return hideFilterStatus(state, action);
        case actionTypes.TOGGLE_ACTIVE_ORDER_FILTER:
            return toggleActiveOrderFilter(state, action);
        case actionTypes.ADD_ORDER_FOR_DELETE:
            return addOrderForDelete(state, action);
        case actionTypes.REMOVE_ORDER_FOR_DELETE:
            return removeOrderForDelete(state, action);
        case actionTypes.RESET_MULTI_SELECTED_ORDER_ID:
            return resetMultiSelectedOrderId(state, action);
        case actionTypes.ORDER_DELETED:
            return orderDeleted(state, action);
        case actionTypes.GET_ORDERS_WITH_FILTERS_SUCCESS:
            return getOrdersWithFiltersSuccess(state, action);
        case actionTypes.RESET_DATA:
            return resetData(state, action);
        default:
            return state;
    }
};

export default reducer;
