import axios from 'axios';

const DCUrl = process.env.REACT_APP_DEVICE_CONNECTOR_URL;
const apozeptURL = process.env.REACT_APP_APOZEPT_SERVICE_URL;

export async function sendRequestToApoOneDC(command: string, data: {}) {
    return new Promise<any>((fulfilled) => {

        const authData: any = {
            url: DCUrl + 'apoone/' + command,
            method: 'POST',
            data: data,
        };

        axios(authData).then(async (response: any) => {

            fulfilled(response);
        }).catch((ex: any) => {
            console.log(ex);
            fulfilled(false);
        });
    })
}

export async function authWithApoOneRequestToken(requestToken: string) {
    return new Promise<any>((fulfilled) => {

        const authData: any = {
            url: apozeptURL + 'api/v2/vitus/apoOneAuth?apoOnePharmacyRequestToken=' + requestToken,
            method: 'GET',
        };

        axios(authData).then(async (response: any) => {

            fulfilled(response);
        }).catch((ex: any) => {
            console.log(ex);
            fulfilled(false);
        });
    })
}

