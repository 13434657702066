import util from 'util';
import axios from 'axios';
import {store} from "../store/configureStore";
import {IOrder} from "../store/types/Orders";
import {sendSetOrderSentToPos} from "./orderHelper";

let abdaAdasUrl = process.env.REACT_APP_ABDA_ADAS_URL;
export async function sendCommandToCashRegister(command: string, user: string, pw: string, orderData?: any) {
    return new Promise<boolean>((fulfilled) => {
        let sendData: { idf: string, kennwort: string, order?: any } = {
            idf: user,
            kennwort: pw
        };

        if (orderData) {
            sendData.order = orderData;
        }

        const authData: any = {
            url: abdaAdasUrl + command,
            method: 'POST',
            data: sendData,
        };


        axios(authData).then(async (response: any) => {
            console.log('response')
            //console.log(util.inspect(response));
            console.log('data', response.data);
            console.log('status', response.status);
            console.log('statusText', response.statusText);

            fulfilled(response.status === 200);
        }).catch((ex: any) => {
            console.log(ex);
            fulfilled(false);
        });
    })

}

type Subset<K> = {
    [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr];
};


interface ShopOrder {
    identity: {
        internal: string | null;
        external: string | null;
    };
    source: {
        identity: string | null;
        name: string | null;
        meta: object;
    };
    seller: {
        identity: {
            internal: string | null;
            external: string | null;
        };
        name: {
            first: string | null;
            last: string | null;
            full: string | null;
        };
        company: string | null;
        contact: {
            email: string | null;
            phone: string | null;
        };
        comments: string[];
    };
    buyer: {
        identity: {
            internal: string | null;
            external: string | null;
        };
        gender: 'F' | 'M' | null;
        salutation: string | null;
        title: string | null;
        name: {
            first: string | null;
            last: string | null;
            full: string | null;
        };
        birthdate: string | null;
        company: string | null;
        contact: {
            email: string | null;
            phone: string | null;
        };
        comments: string[];
    };
    address: {
        billing: {
            line1: string | null;
            line2: string | null;
            postcode: string | null;
            city: string | null;
            country: string | null;
        };
        shipping: {
            line1: string | null;
            line2: string | null;
            postcode: string | null;
            city: string | null;
            country: string | null;
        };
    };
    payment: {
        method: string | null;
        details: object;
        fee: number | null;
        status: string | null;
        date: string | null;
    };
    procurement: {
        method: string | null;
        details: object;
        status: string | null;
        date: string | null;
    };
    delivery: {
        method: string | null;
        details: object;
        fee: number | null;
        status: string | null;
        date: string | null;
    };
    items: ShopOrderItem[];
    discounts: ShopOrderDiscount[];
    totalAmount: number | null;
    totalDiscount: number | null;
    currency: string | null;
    attachments: { [key: string]: ShopOrderAttachment; };
    created: string | null;
}

interface ShopOrderItem {
    product: {
        identity: {
            internal: string | null;
            external: string | null;
            global: string | null;
        };
        name: string | null;
        description: string | null;
        attributes: object;
    };
    quantity: number | null;
    price: number | null;
    attachment: {
        reference: string | null;
        details: object;
    };
}

interface ShopOrderDiscount {
    description: string | null;
    conditions: string[];
    code: string | null;
    amount: number | null;
}

interface ShopOrderAttachment {
    name: string | null;
    description: string | null;
    data: {
        type: string | null;
        encoding: string | null;
        payload: any;
    };
}

function convertToShopOrder(order: Partial<IOrder>): Subset<ShopOrder> {
    let result: Subset<ShopOrder> = {
        identity: {
            internal: '' + order.orderId,
        },
        buyer: {
            name: {
                full: order.user?.name
            }
        },
        items: [],
        created: order.timestamp
    }

    if (order.items) {
        for (let i = 0; i < order.items.length; i++) {
            let orderItem = order.items[i];
            if (!result.items) {
                result.items = [];
            }
            let shopOrderItem: Subset<ShopOrderItem> = {
                product: {
                    identity: {
                        internal: 'item_' + orderItem.itemId,
                    },
                }
            }
            if (orderItem.pzn) {
                shopOrderItem.product!.identity!.external = orderItem.pzn;
            }
            if (orderItem.text) {
                if (orderItem.pzn) {
                    shopOrderItem.product!.name = orderItem.text;
                } else {
                    shopOrderItem.product!.name = 'Text';
                    shopOrderItem.product!.description = orderItem.text;
                }
            }
            if (orderItem.imageData) {
                shopOrderItem.product!.name = 'Bild';
                shopOrderItem.attachment = {
                    reference: 'image_item_' + orderItem.itemId
                }
                if (!result.attachments) {
                    result.attachments = {};
                }
                result.attachments['image_item_' + orderItem.itemId] = {
                    name: 'image_item_' + orderItem.itemId,
                    data: {
                        type: 'image/jpeg',
                        encoding: 'base64',
                        payload: orderItem.imageData
                    }
                };
            } else if (orderItem.eprescriptionText) {
                shopOrderItem.attachment = {
                    reference: 'image_item_' + orderItem.itemId
                }
                if (!result.attachments) {
                    result.attachments = {};
                }
                result.attachments['image_item_' + orderItem.itemId] = {
                    name: 'dataMatrix',
                    data: {
                        payload: orderItem.eprescriptionText
                    }
                };
            }
            if (orderItem.quantity && orderItem.quantity > 0) {
                shopOrderItem.quantity = orderItem.quantity;
            }
            result.items!.push(shopOrderItem);
        }
    }

    if (order.messages) {
        for (let i = 0; i < order.messages.length; i++) {
            let message = order.messages[i];
            if (message.from === 'user' && message.type === 'text') {
                if (!result.buyer?.comments) {
                    result.buyer!.comments = [];
                }
                result.buyer!.comments.push(message.text);
            }
        }
    }

    return result;
}
export async function sendOrderToCashRegister(order: IOrder) {
    try {
        const shopOrder = convertToShopOrder(order);
        console.log('shopOrder', util.inspect(shopOrder, {depth: 10}));
        const username = store.getState().cashRegister.cashRegisterUsername;
        const password = store.getState().cashRegister.cashRegisterPassword;
        let result = await sendCommandToCashRegister('order', username, password, shopOrder);
        if (result) {
            await sendSetOrderSentToPos(order.orderId);
        }

        return result;

    } catch (ex) {
        return false;
    }
}

