import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import {CashRegister} from "../types/CashRegister";
import {CashRegisterActions} from "../types/CashRegisterTypes";
const initialState: CashRegister = {
    cashRegisterUsername: localStorage.getItem('cashRegisterUsername'),
    cashRegisterPassword: localStorage.getItem('cashRegisterPassword'),
    showCashRegisterAuthView: false,
    active: localStorage.getItem('cashRegisterActive') === 'true' ? true : false
};

const authSave = (state: CashRegister, action: any) => {
    return updateObject(state, {
        cashRegisterUsername: action.username,
        cashRegisterPassword: action.password,
    });
};

const activateCashRegister = (state: CashRegister, action: any) => {
    console.log('action', action);
    return updateObject(state, {
        active: action.active,
    });
};

const showCashRegisterAuthView = (state: CashRegister, action: any) => {
    return updateObject(state, {
        showCashRegisterAuthView: true,
    });
};
const hideCashRegisterAuthView = (state: CashRegister, action: any) => {
    return updateObject(state, {
        showCashRegisterAuthView: false,
    });
};

const reducer = (state = initialState, action: CashRegisterActions): CashRegister => {
    switch (action.type) {

        case actionTypes.AUTH_SAVE:
            return authSave(state, action);

        case actionTypes.AUTH_SHOW_VIEW:
            return showCashRegisterAuthView(state, action);

        case actionTypes.AUTH_HIDE_VIEW:
            return hideCashRegisterAuthView(state, action);

        case actionTypes.AUTH_ACTIVATE:
            return activateCashRegister(state, action);

        default:
            return state;
    }
};

export default reducer;
