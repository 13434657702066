import React, {useState} from 'react';
import Modal from "../../UI/Modal/Modal";
import logo from "../../../assets/images/vitusLogo.png";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/configureStore";
import * as actions from "../../../store/actions/index";
import Spinner from "../../UI/Spinner/Spinner";
import {deletePrivateKey, getPrivateKey, storePrivateKey} from "../../../shared/csafeSocketApi";

interface IProps {
}

const CsafeView: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const show = useSelector((state: AppState) => state.auth.showCsafeView);
    const csafeObjectId: any = localStorage.getItem('csafeObjectId');
    const [isUploading, setIsUploading] = useState(false);
    const privateKey: any = useSelector((state: AppState) => state.auth.privateKey);
    const allOrders = useSelector((state: AppState) => state.orders.allOrders);


    const onRemoveKey = async () => {
        if (csafeObjectId) {
            await deletePrivateKey(csafeObjectId);
            dispatch(actions.hideCsafeModal());
            if (privateKey) {
                localStorage.setItem('privateKey', privateKey);
                localStorage.setItem('isBackupDone', '1');
            }
            localStorage.removeItem('csafeObjectId');
        }
    };

    const onUploadKey = async () => {
        setIsUploading(true);
        let connectionId: any = localStorage.getItem('csafeConnectionId');
        let key = privateKey ? privateKey : localStorage.getItem('privateKey');
        if (key && connectionId) {
            let data = {
                key: key,
                connectionId: connectionId,
                name: 'privateKey.key',
                contentType: 'text'
            };
            await storePrivateKey(data);
            localStorage.setItem('isBackupDone', '1');

            setTimeout(async () => {
                let resultObject: any = await getPrivateKey();
                if (resultObject) {
                    localStorage.setItem('csafeObjectId', resultObject.objectId);
                    localStorage.removeItem('privateKey');
                    dispatch(actions.hideCsafeModal());
                    dispatch(actions.getAllOrders());
                    // dispatch(actions.getChatList());
                    setIsUploading(false);
                }
            }, 2000);

        }
    };

    const onCancelUpload = () => {
        if (privateKey) {
            localStorage.setItem('privateKey', privateKey);
        }
        dispatch(actions.hideCsafeModal());
        if (!Object.keys(allOrders).length) {
            dispatch(actions.getAllOrders());
            // dispatch(actions.getChatList());
        }
    };
    return (
        <Modal
            show={show}
            modalClosed={() => {}}
        >
            <div className="modalContainer">
                <div className="header">
                    <img src={logo} alt="Logo" />
                    <h4 className="title">
                        Schlüssel Verwalten
                    </h4>
                </div>

                <p className="description">
                    Sichern Sie Ihren Schlüssel in Csafe.
                </p>
                {
                    csafeObjectId ? (
                        <React.Fragment>
                            <div className="selectBox" onClick={onRemoveKey}>
                                <div className="headline">
                                    Schlüssel entfernen
                                </div>
                                <div className="desc">
                                    Entfernen Sie Ihren Schlüssel aus der Csafe Cloud.
                                </div>
                            </div>
                            <div className="selectBox" onClick={() => dispatch(actions.hideCsafeModal())}>
                                <div className="headline">
                                    Close
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        isUploading ? <Spinner/> :
                        (
                            <React.Fragment>
                                <div className="selectBox" onClick={onUploadKey}>
                                    <div className="headline">
                                        Schlüssel Upload
                                    </div>
                                    <div className="desc">
                                        Laden Sie Ihren Schlüssel in die Csafe Cloud hoch.
                                    </div>
                                </div>
                                <div className="selectBox" onClick={onCancelUpload}>
                                    <div className="headline">
                                        Don't Upload
                                    </div>
                                    <div className="desc">
                                        Don't upload Schlüssel to Csafe cloud.
                                    </div>
                                </div>
                            </React.Fragment>
                        )

                    )
                }
            </div>
        </Modal>
    );
};

export default CsafeView;
