import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Navbar.scss';
import logo from '../../../assets/images/vitusLogo.png';
import * as actions from '../../../store/actions';
import {AppState} from "../../../store/configureStore";
import BackupKey from "../../UI/BackupKey/BackupKey";
import printController from "../../../shared/printController";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {IOrder} from "../../../store/types/Orders";
import {Howl} from "howler";
import AuthData from "../../UI/CashRegister/AuthData";
import packageJson from '../../../../package.json';

const Navbar: React.FC = () => {
    const pharmacy = useSelector((state: AppState) => {
        return {
            name: state.auth.pharmacyName,
            idf: state.auth.pharmacyIdf,
            city: state.auth.pharmacyCity,
            zip: state.auth.pharmacyZip
        }
    });
    const selectedOrderId = useSelector((state: AppState) => state.orders.selectedOrderId);
    const order = useSelector((state: AppState) => state.orders.allOrders[selectedOrderId]);
    const isPrivateKeyAvailable = useSelector((state: AppState) => state.auth.isPrivateKeyAvailable);
    const isPublicKeyAvailable = useSelector((state: AppState) => state.auth.isPublicKeyFoundInServer);
    const showBackupKeyView = useSelector((state: AppState) => state.auth.showBackupKeyView);
    const showCashRegisterAuthView = useSelector((state: AppState) => state.cashRegister.showCashRegisterAuthView);

    const hideBackupKeyView = () => {
        let isKeyBackupDone = localStorage.getItem('isBackupDone') === '1';
        if (isKeyBackupDone) {
            dispatch(actions.hideBackupKeyView());
        }
    };

    const hideCashRegisterAuthView = () => {
        dispatch(actions.hideCashRegisterAuthView());
    };

    const dispatch = useDispatch();
    const onLogout = () => {
        dispatch(actions.authLogout());
        dispatch(actions.resetData());
        dispatch(actions.resetChatData());
    };
    const showBackupKeyViewClicked = () => {
        console.log('showBackupKeyViewClicked() clicked')
        dispatch(actions.showBackupKeyView());
    };

    const showCashRegisterAuthViewClicked = () => {
        dispatch(actions.showCashRegisterAuthView(true));
    };
    const printOrder = (withChat: boolean) => {
        if (order) {
            let content = printController.getHTML(order, withChat);
            let pri: any = document.getElementById("ifmcontentstoprint");
            if (pri) {pri = pri.contentWindow;}
            pri.document.open();
            pri.document.write(content);
            pri.document.close();
            pri.focus();
            pri.print();
        }
    };

    const badgeCount = useSelector((state: AppState) => {
        let counter = 0;
        let allOrders: IOrder[] = state.orders.allOrders;
        for (let key in allOrders) {
            if (allOrders.hasOwnProperty(key)) {
                let isUnreadMessageAvailable = false;
                for (let i = 0; i < allOrders[key].messages.length; i++) {
                    if (!allOrders[key].messages[i].readByPharmacy) {
                        isUnreadMessageAvailable = true;
                        break;
                    }
                }

                if (allOrders[key].hasImportantMessage || isUnreadMessageAvailable) {
                    counter = counter + 1;
                }
            }
        }
        return counter;
    });

    useEffect(() => {
        if (badgeCount > 0) {
            let soundNewMessage = new Howl({src: require('../../../assets/sounds/notify.mp3')});
            soundNewMessage.play();
        }
    },[badgeCount]);

    return (
        <React.Fragment>
            <div className="navbarContainer">
                <img src={logo} alt="Logo" />
                <h5>{pharmacy.name}</h5>

                <div className="dropdown">
                    <button className="dropbtn">Menu &nbsp;&nbsp;<FontAwesomeIcon icon="caret-down"/></button>
                    <div className="dropdown-content">
                        {
                            isPrivateKeyAvailable && isPublicKeyAvailable && (
                                <React.Fragment>
                                    <button className="navbarBtn" onClick={showBackupKeyViewClicked}><FontAwesomeIcon icon="key"/>&nbsp;&nbsp; Schlüssel sichern</button>
                                    <button className="navbarBtn" onClick={() => printOrder(false)}><FontAwesomeIcon icon="print"/>&nbsp;&nbsp; Drucken</button>
                                    <button className="navbarBtn" onClick={() => printOrder(true)}><FontAwesomeIcon icon="print"/>&nbsp;&nbsp; Drucken mit Chat</button>
                                    <button className="navbarBtn" onClick={showCashRegisterAuthViewClicked}><FontAwesomeIcon icon= "life-ring" />&nbsp;&nbsp; Kassen System</button>
                                </React.Fragment>
                            )
                        }
                        {
                            localStorage.getItem('csafeConnectionId') && (
                                <button className="navbarBtn" onClick={() => {dispatch(actions.showCsafeModal())}}><FontAwesomeIcon icon="user-shield"/>&nbsp;&nbsp; Csafe</button>
                            )
                        }
                        <button className="navbarBtn" onClick={onLogout}><FontAwesomeIcon icon="sign-out-alt"/>&nbsp;&nbsp; Logout</button>

                        <p style={{
                            color: '#CCCCCC',
                            fontSize: '10px',
                            textAlign: 'center',
                        }}>v {packageJson.version}</p>
                    </div>
                </div>
            </div>

            <BackupKey show={showBackupKeyView} hide={() => hideBackupKeyView()}/>
            <AuthData show={showCashRegisterAuthView} hide={() => hideCashRegisterAuthView()}/>

            <iframe title='print' id="ifmcontentstoprint" style={{height: 0, width: 0, position: 'absolute'}} />

        </React.Fragment>

    );
};

export default Navbar;
