import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../UI/Modal/Modal';
import logo from '../../../assets/images/vitusLogo.png';
import cloudIcon from '../../../assets/images/cloud-icon.png';
import harddriveIcon from '../../../assets/images/harddrive-icon.png';
import './PrivateKeyNotFoundView.scss';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/configureStore';
import Input from "../../UI/Input/Input";

interface IProps {
    show: boolean;
}

let errorMessage: string = '';

const PrivateKeyNotFoundView: React.FC<IProps> = props => {
    const authLoading = useSelector((state: AppState) => state.auth.loading);
    const rsaKeyLoading = useSelector((state: AppState) => state.auth.rsaKeyLoading);
    const isPrivateKeyValid = useSelector((state: AppState) => state.auth.isPrivateKeyValid);
    const privateKeyErrorMsg: string = useSelector((state: AppState) => state.auth.privateKeyErrorMsg);
    const hasPvtKeyOnCloud: boolean = useSelector((state: AppState) => state.auth.hasPvtKeyOnCloud);
    const dispatch = useDispatch();

    const [showPasswordWindow, setShowPasswordWindow] = useState(false);
    const [password, setPassword] = useState('');

    let cloudImportClass = "selectBox" + (hasPvtKeyOnCloud ? "" : " disabled");

    const keyChangeHandler = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        let file = event.dataTransfer
            ? event.dataTransfer.files[0]
            : event.target.files[0];

        if (file) {
            let reader = new FileReader();
            reader.onload = onReaderLoad;
            reader.readAsText(file);
        }
    };

    const onReaderLoad = async (event: any) => {

        let privateKey = event.target.result;
        // now we got the uploaded key we need to check if it is valid or not
        dispatch(actions.checkPrivateKeyAndLoadOrders(privateKey, false, false));
    };
    // The function below helps bypass the problem where onChange event doesn't trigger for same file
    const onInputClick = (event: any) => {
        event.target.value = '';
    };

    const getFromCloudAndStore = () => {
        if (password.length) {
            dispatch(actions.getKeyFromCloudAndStore(password));
        } else {
            dispatch(actions.setPrivateKeyErrorMsg('Passwort leer'));
        }
    }

    let keyFileInput = (
        <input
            id="myInput"
            type="file"
            onChange={e => keyChangeHandler(e)}
            onClick={e => onInputClick(e)}
        />
    );

    return (
        <Modal
            show={props.show && !rsaKeyLoading}
            modalClosed={() => console.log('close modal')}
        >
            {authLoading ? (
                'Loading ....'
            ) : (
                <div className="modalContainer">
                    <div className="header">
                        <img src={logo} alt="Logo" />
                        <h4 className="title">
                            Schlüssel importieren
                        </h4>
                    </div>
                    { (errorMessage.length > 0) &&
                        <div className={'error'}>
                            <p>{errorMessage}</p>
                        </div>
                    }
                    {!isPrivateKeyValid ? (
                        <div className="errorMsg">
                            Privater Schlüssel ist fehlerhaft. Bitte importieren
                            Sie den passenden.
                        </div>
                    ) : null}
                    {privateKeyErrorMsg && privateKeyErrorMsg.length > 0 ? (
                        <div className="errorMsg">
                            {privateKeyErrorMsg}
                        </div>
                    ) : null}

                    <p className="description">
                        Privater Schlüssel ist fehlerhaft. Bitte importieren Sie den passenden.
                    </p>

                    {!showPasswordWindow &&
                        <div>

                            <div className={'row'}>

                                <div className="selectBox">
                                    <img className={'icon'} src={harddriveIcon} alt="cloud icon" />

                                    <div className="headline">
                                        Festplatte
                                    </div>
                                    <div className="desc">
                                        Schlüssel von Festplatte importieren
                                    </div>
                                    <div className={'action'}>

                                        <a className={'button'}>Schlüssel importieren</a>
                                        {keyFileInput}
                                    </div>
                                </div>

                                <div className={cloudImportClass} >
                                    <img className={'icon'} src={cloudIcon} alt="cloud icon" />

                                    <div className="headline">
                                        Cloud
                                    </div>
                                    <div className="desc">
                                        Schlüssel von Cloud importieren
                                    </div>
                                    <div className={'action'}>
                                        <a onClick={() => {
                                            setShowPasswordWindow(true)
                                        }}
                                            className={'button'}>Schlüssel importieren</a>
                                    </div>
                                </div>
                            </div>

                        <div className={'supportText'}>
                            Benötigen Sie unterstützung? Schreiben Sie eine E-Mail an unseren Support info@comvalue.com
                            <a
                                href="mailto:info@comvalue.com"
                                style={{ textDecoration: 'none' }}
                            > info@comvalue.com</a>
                        </div>
                    </div>
                    }
                    {showPasswordWindow &&
                        <div className={'column'}>
                            {!isPrivateKeyValid && (
                                <div className={'error'}>
                                    Passwort fehlgeschlagen oder ungültiger Schlüssel
                                </div>
                            )}
                            <p>
                                Bitte geben Sie das Passwort ein, um den Schlüssel zu überprüfen und herunterzuladen.
                            </p>
                            <Input
                                elementType={'password'}
                                elementConfig={{
                                    type: 'password',
                                    label: 'password',
                                    options: undefined
                                }}
                                value={password}
                                changed={function (event: any): void {
                                    setPassword(event.target.value);
                                }}
                                invalid={false}
                                shouldValidate={ {required: true} }
                                touched={false}
                                autoFocus={false}
                            >

                            </Input>
                            <div className={"column actions"}>

                                <button onClick={async () => {
                                    getFromCloudAndStore()
                                }}>
                                    Download Schlüssel aus der Cloud
                                </button>

                                <button className={'cancel'} onClick={async () => {
                                    setShowPasswordWindow(false)
                                    setPassword('')
                                }}>Züruck
                                </button>
                            </div>
                        </div>
                    }
                </div>
            )}
        </Modal>
    );
};

export default PrivateKeyNotFoundView;
